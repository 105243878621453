<template>
<v-card max-width="800" elevation="0">
  <v-toolbar class="grey darken-2" elevation="1">
    <v-toolbar-title class="white--text">
      {{ title }}
    </v-toolbar-title>
  </v-toolbar>
  <v-card-text class="mt-2">
    <v-form ref="form" v-model="formValid">
      <v-row>
        <v-col cols="12" class="pt-4">
          <v-switch
              class="mt-1"
              v-model="all_flats"
              hide-details
              label="Усі абоненти організації"
              color="secondary"/>
        </v-col>
        <v-col cols="12">
          <AddressElementSelect v-model="city_id"
                                label="Населений пункт" filled
                                select_type="city"
                                :disabled="all_flats"
          />
        </v-col>
        <v-col cols="12">
          <AddressElementSelect :parent_id="city_id" v-model="streets"
                                label="Вулиця(-ки)" multiple filled
                                select_type="street" use_parent_id
                                :disabled="!city_id || all_flats"
          />
        </v-col>
        <v-col cols="12">
          <AddressElementSelect :parent_id="streets" v-model="buildings"
                                label="Будинок(-ки)" multiple filled
                                select_type="building" use_parent_id
                                :disabled="!streets.length || all_flats"
          />
        </v-col>
        <v-col cols="12">
          <v-btn depressed text block color="secondary darken-1" class="button-accept" :disabled="!documentReady()" @click.stop="finishModal()">
            Виконати
          </v-btn>
          <v-btn depressed block color="grey lighten-3" class="mt-3" @click.stop="closeModal()">
            Відмінити
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: "HWP_Fill_Address",
  props: ['title', 'item', 'dialog'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      formValid: false,
      city_id: this.item.city_id || null,
      streets: this.item.streets || [],
      buildings: this.item.buildings || [],
      all_flats: this.item.all_flats || false,
      watcher: null
    }
  },
  methods: {
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          return false
        }
      }
      return true
    },
    onCityChange(payload) {
      this.city_id = payload.value || null
    },
    onStreetChange(payload) {
      this.streets = payload.map(item => item.value) || []
    },
    onBuildingChange(payload) {
      this.buildings = payload.map(item => item.value) || []
    },
    closeModal() {
      this.$emit('closeModal')
      this.city_id = this.item.city_id || null
      this.streets = this.item.streets || []
      this.buildings = this.item.buildings || []
      this.all_flats = this.item.all_flats || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    finishModal() {
      const payload = {
        city_id: this.city_id,
        streets: this.streets,
        buildings: this.buildings,
        all_flats: this.all_flats
      }
      this.$emit('crud', payload)
      this.closeModal()
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.city_id = payload.city_id || null
              this.streets = payload.streets || []
              this.buildings = payload.buildings || []
              this.all_flats = payload.all_flats || false
            }
          }
      )
    },
  },
  created() {
    this.watch_item()
  },
  beforeDestroy() {
    if (this.watcher) {
      this.watcher()
    }
  }
}
</script>

<style scoped>

</style>